import { CmsSeoMetaInput } from '~/types/cms/contentModel'

export const useCmsSeo = (
  data: Ref<CmsSeoMetaInput | null>,
  title = 'Mercedes-Benz | Online Showroom'
) => {
  const {
    public: { baseUrl }
  } = useRuntimeConfig()
  const route = useRoute()
  const ogImageURL = data.value?.ogimage?.file?.url

  const ogImage = ogImageURL?.startsWith('https:')
    ? ogImageURL
    : 'https:' + ogImageURL

  const robots = data.value?.robots ?? 'index' // Retialer Mode should be noindex, also check static pages
  const description =
    data.value?.pageMetaTagDescription ?? 'Find your new Mercedes-Benz here'
  const keywords = data.value?.pageMetaTagKeywords ?? ''
  const appleMobileWebAppTitle = data.value?.ogtitle ?? title
  const ogTitle = data.value?.ogtitle ?? title
  const ogDescription =
    data.value?.ogdescription ?? 'Find your new Mercedes-Benz here'
  const ogUrl = baseUrl + route.path
  const ogSiteName = data.value?.ogsitename ?? title
  const ogType = data.value?.ogtype ?? 'website'
  const twitterCard = data.value?.twittercard ?? 'summary_large_image'
  const twitterSite = data.value?.twittersite ?? '@mercedesbenzuk'
  const twitterCreator = data.value?.twittercreator ?? '@mercedesbenzuk'
  const twitterTitle = data.value?.twittertitle ?? title
  const twitterDescription =
    data.value?.twitterdescription ?? 'Find your new Mercedes-Benz here'

  // SEO Meta
  useSeoMeta({
    title: () => title,
    robots: () => robots,
    description: () => description,
    keywords: () => keywords,
    appleMobileWebAppTitle: () => appleMobileWebAppTitle,
    ogTitle: () => ogTitle ?? title,
    ogDescription: () => ogDescription,
    ogUrl: () => ogUrl,
    ogSiteName: () => ogSiteName,
    ogType: () => ogType,
    ogImage: () => ogImage,
    twitterCard: () => twitterCard,
    twitterSite: () => twitterSite,
    twitterCreator: () => twitterCreator,
    twitterTitle: () => twitterTitle,
    twitterDescription: () => twitterDescription
  })
}
